import anylogger from '@app/anylogger'
import React, { useMemo, useContext, ReactComponentElement } from 'react'
import { Text } from './Text'
import { CheckboxEditor, DateEditor, ItemSelectorEditor, MultilineEditor, NumberEditor, StringEditor } from './DefaultFieldEditors'
import { JVPInit, JsonValueProvider } from '@app/utils'
import { EditorConfigInit, EditorConfig } from './EditorConfig'

const log = anylogger('EditorService')

export class FieldDefinition<T extends object = any> {
	name: string
	provider: JsonValueProvider<T>
	editor: EditorConfig
	static initList<T extends object = any>(fieldList: FieldDefInit<T>[]) {
		return fieldList.map((ft) => FieldDefinition.init(ft))
	}
	static init<T extends object = any>(field: FieldDefInit<T>) {
		if (!Array.isArray(field)) return field as FieldDefinition<T>
		const fieldType = field.length > 2 && field[2] ? field[2] : 'string'
		return new FieldDefinition(field[0], JsonValueProvider.init(field[1]), EditorConfig.init(fieldType))
	}
	constructor(name: string, provider: JsonValueProvider<T>, editor: EditorConfig) {
		this.name = name
		this.provider = provider
		this.editor = editor
	}
}
export type FieldDefInit<T extends object = any> = [string, JVPInit<T>] | [string, JVPInit<T>, EditorConfigInit<T>]

export interface FieldEditorProps<T extends object> {
	fieldDef: FieldDefinition<T>
	data: any
	dataChanged: (data: T) => void
	parentRef?: Element
}

// class Service {
// 	private editors: Record<string, React.FunctionComponent<FieldEditorProps<any>>> = {}
// 	constructor() {
// 		this.addEditor('string', StringEditor)
// 		this.addEditor('multiline', MultilineEditor)
// 		this.addEditor('number', NumberEditor)
// 		this.addEditor('bigint', NumberEditor)
// 		this.addEditor('date', DateEditor)
// 		this.addEditor('boolean', CheckboxEditor)
// 		this.addEditor('itemSelector', ItemSelectorEditor)
// 	}
// 	addEditor(key: string, editor: any) {
// 		this.editors[key] = editor
// 	}

// 	getEditor<T extends object>(
// 		key: any,
// 		field: FieldDefinition<T>,
// 		data: T,
// 		dataChanged: (data: T) => void,
// 		parentRef?: Element | null
// 	): any {
// 		const editor = this.editors[field.editor.type]
// 		if (!editor) return <Text>{`Unknown editor: ${field.editor.type}`}</Text>
// 		return React.createElement(editor, { key, fieldDef: field, data, dataChanged, parentRef: parentRef ? parentRef : undefined })
// 	}
// }

// export const EditorServiceContext = React.createContext<Service>(undefined!)

// export function useEditorService(): Service {
// 	const context = useContext(EditorServiceContext)
// 	return context
// }

// export const EditorService = (props: any) => {
// 	const service = useMemo(() => new Service(), [])
// 	return <EditorServiceContext.Provider value={service}>{props.children}</EditorServiceContext.Provider>
// }
