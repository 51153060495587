import { useCallback, useContext } from 'react'
import { CalendarDisplayEvent } from './CalendarDisplayEvent'
import { usePermission } from 'src/services/usePermission'
import { CalendarContext } from './CalendarContext'
import { daysDiff, formatDateTime } from '@app/utils'
import { Flex, Text } from '@app/muiplus'
import { DialogContent, DialogTitle, IconButton, Popover } from '@mui/material'
import ModeEdit from '@mui/icons-material/ModeEdit'
import Close from '@mui/icons-material/Close'
import { getDateFromUnion } from './calendarUtils'

interface CalendarDetailsProps {
	anchorEl: HTMLElement | null
	displayEvent: CalendarDisplayEvent | undefined
	onClose: () => void
}
export function CalendarDetails(props: CalendarDetailsProps) {
	let { displayEvent, onClose, anchorEl } = props
	const { editEvent } = useContext(CalendarContext)
	const event = displayEvent?.event

	const perm = usePermission()

	const edit = useCallback(() => {
		if (!event) return null
		editEvent(event)
		onClose()
	}, [editEvent, event, onClose])

	const getDetails = useCallback(() => {
		if (!event) return null
		const sDate = getDateFromUnion(event.start)
		const eDate = getDateFromUnion(event.end)
		const time = event.start.dateTime ? formatDateTime(event.start.dateTime, 'hh:nn') + ' ' : ''
		let dateStr = formatDateTime(sDate!, 'wdl mmmm dd, yyyy')
		if (time) dateStr = dateStr + ' - ' + time
		const isMultiDay = daysDiff(sDate, eDate) > 1
		if (isMultiDay) {
			let tmpStr = formatDateTime(eDate, 'wdl dd mmmm yyyy')
			let time = event.end.dateTime ? formatDateTime(event.end.dateTime, 'hh:nn') + ' ' : ''
			if (time) tmpStr = tmpStr + ', ' + time
			dateStr = dateStr + ' - ' + tmpStr
		}

		let res = <Text>{dateStr}</Text>
		let desc = null
		if (event.description) desc = <Text>{event.description}</Text>
		return (
			<>
				{res}
				{desc}
			</>
		)
	}, [event])

	if (!event) return null

	const editButton = perm.isCalendarEditor ? (
		<IconButton onClick={edit}>
			<ModeEdit />
		</IconButton>
	) : null

	return (
		<Popover
			open={Boolean(event)}
			elevation={24}
			onClose={onClose}
			slotProps={{ paper: { sx: { borderRadius: '1em' } } }}
			sx={{
				borderRadius: '1000%'
				// maxWidth: '1000px'
			}}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
		>
			<DialogTitle>
				<Flex justifyContent="space-between">
					<Text>{event.summary}</Text>
					<Flex alignItems="start">
						{editButton}
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Flex>
				</Flex>
			</DialogTitle>

			<DialogContent
				sx={{
					maxWidth: '800px'
				}}
			>
				{getDetails()}
			</DialogContent>
		</Popover>
	)
}
