import { FieldDefInit, EditDialog, Field, FlexC, Flex, Text, ICustomDialogCallbackProps, ItemSelector } from '@app/muiplus'
import { useCallback, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { Checkbox, TextField } from '@mui/material'
import { adjustDate, formatDateTime, toLocaleISOFormat } from '@app/utils'
import { GoogleEvent } from '@app/googlecalendar'
import { ICloudStorage } from '@app/cloudstorage'
import { ClientProxy } from '@app/clientserverutils'

const log = anylogger('CalendarEditDialog')

export function CalendarEditDialog({ event, dataChanged }: CalendareEditDialogProps) {
	if (!dataChanged) throw new Error(`You must specify dataChanged`)
	const [allDay, setAllDay] = useState(event.start.date ? true : false)
	log('event', event)

	const fields: FieldDefInit[] = [
		['Title', 'summary', 'string'],
		['Description', 'description', ['multiline', { context: { minRows: 5, maxRows: 10 } }]]
		// ['Description', 'description', 'string'],
		// ['Date Taken', 'dateTaken', ['date', { disabled: isTrue(meta.dtRO) }]],
		// ['Date RO', 'dtRO', 'boolean'],
		// ['Author', 'author', ['string', { disabled: true }]],
	]

	return (
		<EditDialog<any> data={event} fields={[]} dataChanged={dataChanged}>
			<Field fieldDef={fields[0]} />
			<FlexC>
				<StartTime event={event} dataChanged={dataChanged} onAllDayChanged={setAllDay} />
				<EndTime event={event} dataChanged={dataChanged} allDay={allDay} />
			</FlexC>
			<CalendarPageLinkField event={event} dataChanged={dataChanged} />
			<Field fieldDef={fields[1]} />
		</EditDialog>
	)
}

export interface CalendareEditDialogProps extends ICustomDialogCallbackProps {
	event: GoogleEvent
}
interface CalendarEventFieldProps {
	event: GoogleEvent
	dataChanged: (data: any) => void
}
interface StartTimeProps extends CalendarEventFieldProps {
	onAllDayChanged: (allDay: boolean) => void
}
export function StartTime(props: StartTimeProps) {
	const { event, dataChanged, onAllDayChanged } = props
	const [allDay, setAllDay] = useState(event.start.date ? true : false)
	const [date, setDate] = useState(
		event.start.date || (event.start.dateTime && formatDateTime(new Date(event.start.dateTime), 'yyyy-mm-dd'))
	)
	const [time, setTime] = useState<string>(() => {
		if (!event.start.dateTime) return '00:00'
		const dt = new Date(event.start.dateTime)
		return formatDateTime(dt, 'hh:nn')
	})

	useEffect(() => {
		if (allDay) {
			event.start.date = date
			delete event.start.dateTime
		} else {
			delete event.start.date
			event.start.dateTime = toLocaleISOFormat(date!, time)
		}
		log('updatingStartime', event.start)

		if (dataChanged) dataChanged(event)
	}, [allDay, dataChanged, date, event, event.start, time])
	const allDayChanged = useCallback(
		(e: any) => {
			setAllDay(e.target.checked)
			onAllDayChanged(e.target.checked)
		},
		[onAllDayChanged]
	)
	const dateChanged = useCallback((e: any) => {
		setDate(e.target.value)
	}, [])
	const timeChanged = useCallback((e: any) => {
		setTime(e.target.value)
	}, [])
	return (
		<Flex alignItems="center">
			<TextField
				type="date"
				value={date}
				onChange={dateChanged}
				sx={{
					width: '8em'
				}}
			/>
			<Text>All Day</Text>
			<Checkbox checked={allDay} onChange={allDayChanged} />
			{!allDay && (
				<TextField
					type="time"
					value={time}
					onChange={timeChanged}
					sx={{
						width: '8em'
					}}
				/>
			)}
		</Flex>
	)
}
interface EndTimeProps extends CalendarEventFieldProps {
	allDay: boolean
}
export function EndTime(props: EndTimeProps) {
	const { event, dataChanged, allDay } = props
	const [date, setDate] = useState(() => {
		if (event.end.date) {
			const dt = formatDateTime(adjustDate(event.end.date, -1), 'yyyy-mm-dd')
			log('dt', dt)

			return dt
		} else return event.end.dateTime && formatDateTime(new Date(event.end.dateTime), 'yyyy-mm-dd')
	})
	const [time, setTime] = useState<string>(() => {
		if (!event.end.dateTime) return '00:00'
		const dt = new Date(event.end.dateTime)
		return formatDateTime(dt, 'hh:nn')
	})

	useEffect(() => {
		if (allDay) {
			event.end.date = formatDateTime(adjustDate(date!, 1), 'yyyy-mm-dd')
			delete event.end.dateTime
		} else {
			delete event.end.date
			event.end.dateTime = toLocaleISOFormat(date!, time)
		}
		log('updatingEndTime', event.end)

		if (dataChanged) dataChanged(event)
	}, [allDay, dataChanged, date, event, event.end, event.start, time])
	const dateChanged = useCallback((e: any) => {
		setDate(e.target.value)
	}, [])
	const timeChanged = useCallback((e: any) => {
		setTime(e.target.value)
	}, [])
	return (
		<Flex alignItems="center">
			<TextField
				type="date"
				value={date}
				onChange={dateChanged}
				sx={{
					width: '8em'
				}}
			/>
			{!allDay && (
				<TextField
					type="time"
					value={time}
					onChange={timeChanged}
					sx={{
						width: '8em'
					}}
				/>
			)}
		</Flex>
	)
}
interface CalendarPageLinkFieldProps extends CalendarEventFieldProps {}
function CalendarPageLinkField(props: CalendarPageLinkFieldProps) {
	const { event, dataChanged } = props
	const [pages, setPages] = useState<string[]>([])
	const [api] = useState<ICloudStorage>(new ClientProxy(new ICloudStorage(), '/api/mdxContent') as ICloudStorage)

	const onChange = useCallback(
		(e: any, value: any) => {
			log('value', value)
			event.location = value
			if (dataChanged) dataChanged(event)
		},
		[dataChanged, event]
	)
	useEffect(() => {
		const res = api.getAll().then((res) => {
			const items = res.list.filter((item) => item.name.toLocaleLowerCase().endsWith('.mdx') && item.name !== 'index.mdx')
			const files = items.map((item) => item.name.replace('.mdx', ''))
			setPages(files)
		})
	}, [api])

	// if (!pages.length) return null

	return <ItemSelector label="Linked Page" value={event.location} onChange={onChange} options={pages} />
}
