import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CalendarContext, CalendarContextType } from './CalendarContext'
import { useCurrentRef } from '@app/hooks'
import { adjustDate, getElementFont, getTextWidth, longMonths, parseDateTime } from '@app/utils'
import { Button, IconButton, default as useTheme } from '@mui/material'
import { CalendarEvent } from './Calendar'
import { CalendarDisplayEvent } from './CalendarDisplayEvent'
import { Flex, Text } from '@app/muiplus'
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight'

export function CalendarHeader() {
	const { selectedDate, setSelectedDate } = useContext(CalendarContext)
	const [textRef, setTextRef] = useCurrentRef<HTMLElement>()
	const [monthWidth, setMonthWidth] = useState(0)

	useEffect(() => {
		if (!textRef) return
		const font = getElementFont(textRef)
		let width = 0
		for (let ii = 0; ii < longMonths.length; ii++) {
			width = Math.max(width, getTextWidth(longMonths[ii], font))
		}
		setMonthWidth(width)
	}, [textRef])

	const { mmmm, yyyy } = parseDateTime(selectedDate)

	const prevYear = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, -365))
	}, [selectedDate, setSelectedDate])

	const nextYear = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, 365))
	}, [selectedDate, setSelectedDate])

	const prevMonth = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, -30))
	}, [selectedDate, setSelectedDate])

	const nextMonth = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, 30))
	}, [selectedDate, setSelectedDate])

	return (
		<Flex fontSize="20px">
			<Button onClick={(e: any) => setSelectedDate(new Date())}>Today</Button>
			<Flex fill alignItems="center" justifyContent="center">
				<IconButton onClick={prevYear}>
					<KeyboardDoubleArrowLeft />
				</IconButton>
				<IconButton onClick={prevMonth}>
					<KeyboardArrowLeft />
				</IconButton>
				<Text width={`${monthWidth}px`} ref={setTextRef} textAlign="center">
					{mmmm}
				</Text>
				<Text>{yyyy}</Text>
				<IconButton onClick={nextMonth}>
					<KeyboardArrowRight />
				</IconButton>
				<IconButton onClick={nextYear}>
					<KeyboardDoubleArrowRight />
				</IconButton>
			</Flex>
		</Flex>
	)
}
