import { FlexC, useCurrentBreakpoint } from '@app/muiplus'
import { CalendarDetails } from './CalendarDetails'
import { Box, lighten, useTheme } from '@mui/material'
import { datesAreEqual, parseDateTime } from '@app/utils'
import { CalendarDisplayEvent } from './CalendarDisplayEvent'
import { useCallback, useContext, useState } from 'react'
import { CalendarContext } from './CalendarContext'
import { useCurrentRef } from '@app/hooks'
import { CalendarEvent } from './CalendarEvent'
import anylogger from '@app/anylogger'

const log = anylogger('CalendarDay')

export function CalendarDay({ date, events }: { date: string; events: CalendarDisplayEvent[] | undefined }) {
	const { selectedDate: selectedDateStr, setSelectedDate } = useContext(CalendarContext)
	const [detailEvent, setDetailEvent] = useState<CalendarDisplayEvent | undefined>(undefined)
	const [ref, setRef] = useCurrentRef<HTMLElement>()

	const thisDate = parseDateTime(date)
	const today = parseDateTime(new Date())
	const selectedDate = parseDateTime(selectedDateStr)
	const theme = useTheme()
	const onCloseDetails = useCallback(() => {
		setDetailEvent(undefined)
	}, [])
	const eventClick = useCallback((e: CalendarDisplayEvent) => {
		setDetailEvent(e)
	}, [])

	const getEvents = useCallback(() => {
		if (!events) return null
		const multiDay = events.filter((e) => e.multiDay)
		const singleDay = events.filter((e) => !e.multiDay)
		let res: any = []
		let idx = 0
		while (multiDay.length || singleDay.length) {
			try {
				let md: CalendarDisplayEvent = multiDay[0]
				const sd = singleDay[0]
				if (md && md.slot == idx) {
					// if this multiDay event applies, then remove it from the list
					multiDay.shift()
					// log('md', md.event.summary, md)
					res.push(<CalendarEvent key={idx} displayEvent={md} onClick={eventClick} isDetailed={md == detailEvent} />)
				} else if (sd) {
					// log('filler', sd.event.summary, sd)
					singleDay.shift()

					res.push(<CalendarEvent key={idx} displayEvent={sd} onClick={eventClick} isDetailed={sd == detailEvent} />)
				} else res.push(<CalendarEvent key={idx} displayEvent={undefined} />)
			} finally {
				idx++
			}
		}

		for (let ii = 0; ii < multiDay.length; ii++) {
			try {
				const md = multiDay[ii]
				if (ii == md.slot) {
					log('md', md.event.summary, md)

					res.push(<CalendarEvent key={idx} displayEvent={md} onClick={eventClick} isDetailed={md == detailEvent} />)
				} else {
					const sd = singleDay.shift()
					if (sd) {
						log('filler', sd.event.summary, sd)

						res.push(<CalendarEvent key={idx} displayEvent={sd} onClick={eventClick} isDetailed={sd == detailEvent} />)
					} else res.push(<CalendarEvent key={idx} displayEvent={undefined} />)
				}
			} finally {
				idx++
			}
		}
		while (singleDay.length) {
			const sd = singleDay.shift()!
			log('remnant', sd.event.summary, sd)

			res.push(<CalendarEvent key={idx} displayEvent={sd} onClick={eventClick} isDetailed={sd == detailEvent} />)
			idx++
		}

		return res

		// return events.map((de: CalendarDisplayEvent, idx: number) => {
		// 	return <CalendarEvent key={idx} displayEvent={de} onClick={eventClick} isDetailed={de == detailEvent} />
		// })
	}, [detailEvent, eventClick, events])
	const setDate = useCallback(() => {
		setSelectedDate(date)
	}, [date, setSelectedDate])
	let bgColor = 'white'
	// log('theme.palette.action.disabledBackground', theme.palette.action.disabledBackground)

	if (thisDate.mm != selectedDate.mm) bgColor = theme.palette.action.disabledBackground
	else if (datesAreEqual(thisDate, selectedDate)) bgColor = lighten(theme.palette.primary.light, 0.7)
	const bkp = useCurrentBreakpoint()
	let borderProps = {}
	// if (bkp == 'mobile') {
	borderProps = { borderRadius: '0px', gap: '0px' }
	// if (thisDate.mm != selectedDate.mm) bgColor = alpha('#FFFFFF', 0.12)
	// } else {
	// 	borderProps = { border: 'solid 1px gray', borderRadius: '0.5em' }
	// }

	const dateBgColor = datesAreEqual(thisDate, today) ? theme.palette.primary.light : undefined
	return (
		<FlexC ref={setRef} p="auto" {...borderProps} bgcolor={bgColor} onClick={setDate} minWidth="0" minHeight="0">
			<Box
				mx="auto"
				bgcolor={dateBgColor}
				// px="0.25em"
				borderRadius="1000%"
				fontSize="1.3em"
				fontWeight="bold"
				sx={{
					aspectRatio: '1/1',
					textAlign: 'center'
				}}
			>
				{thisDate.dd}
			</Box>
			<FlexC fill gap="2px" justifyContent="start">
				{getEvents()}
			</FlexC>
			<CalendarDetails displayEvent={detailEvent} onClose={onCloseDetails} anchorEl={ref} />
		</FlexC>
	)
}
