import { WebMenu, WebMenuItem, logRenderReason } from '@app/utils'
import { getMdxSource } from '@app/easymdx/src/getMdxSource'
import { useSession } from './useSession'
import { IMenu, IMenuList } from '@app/muiplus'
import { useEffect, useState } from 'react'

export function useCombinedMenu(session: any, webMenu: WebMenu, memberMenu: WebMenu): IMenuList {
	const [menu, setMenu] = useState<IMenuList>([])

	useEffect(() => {
		const mapMenu = (menu: WebMenuItem[] | undefined): IMenuList => {
			if (!menu) return []
			return menu.map((m: WebMenuItem) => {
				return { caption: m.caption, data: m.url ?? '', children: mapMenu(m.children) }
			})
		}
		let m: WebMenu = []
		if (memberMenu && session) m.push({ caption: 'Members', url: '/Members/Home', children: [...memberMenu] })
		if (webMenu) m.push(...webMenu)
		setMenu(mapMenu(m))
	}, [session, webMenu, memberMenu])

	return menu
}
