interface Obj {
	[key: string]: string
}
export function createCsv(items: Obj[]) {
	return items.reduce((res, item, idx) => {
		if (idx == 0) {
			const title = Object.entries(item).reduce((res, [key, val], idx) => {
				if (idx > 0) res = res + ','
				res = res + key
				return res
			}, '')
			res = res + title
		}

		let line = Object.entries(item).reduce((res, [key, val], idx) => {
			if (idx > 0) res = res + ','
			res = res + (val ?? '')
			return res
		}, '')
		if (res) res = res + '\n'
		res = res + line
		return res
	}, '')
}
