import anylogger from '@app/anylogger'

export const Bucket = 'bucket'
export const Dir = 'dir'
export const Obj = 'obj'
export type ObjectType = typeof Bucket | typeof Dir | typeof Obj | ''

export class ICloudObject {
	bucketId: string = ''
	id: string = ''
	path: string = ''
	type: ObjectType = ''
	name: string = ''
	size: number = 0
	contentType: string = ''
	etag: string = ''
	updated: string = ''
	created: string = ''
	metadata: any = undefined
	isDir: boolean = false
	directLink?: string
}
export type ICloudObjectList = ICloudObject[]

export type ICloudObjectKey = Partial<Pick<ICloudObject, 'bucketId'>> & Pick<ICloudObject, 'id'>
export interface IPathKey {
	bucketId?: string
	path: string
}

export type ObjectOrIdOrPath = ICloudObject | ICloudObjectKey | IPathKey

export function isObjectKey(obj: ObjectOrIdOrPath): obj is ICloudObjectKey {
	return 'id' in obj
}
export function isPathKey(obj: ObjectOrIdOrPath): obj is IPathKey {
	return 'path' in obj
}
export interface ContentParams {
	contentType?: string
	base64?: string
	metadata?: any
}

export type AddParams = IPathKey & ContentParams
export type UpdateParams = ObjectOrIdOrPath & ContentParams

export interface PagedRequestParams {
	pageSize: number
	pageToken?: string
}
export interface PagedResults {
	pageToken?: string
	list: ICloudObjectList
}

export class ICloudStorage {
	// Capabilities
	supportsFreeFormMetadata!: () => boolean
	supportsPaging!: () => boolean

	getBuckets!: () => Promise<ICloudObjectList>
	getAll!: (bucketId?: string, pageParams?: PagedRequestParams) => Promise<PagedResults>
	getDirectory!: (params: ObjectOrIdOrPath, pageParams?: PagedRequestParams) => Promise<PagedResults>

	getObject!: (params: ObjectOrIdOrPath) => Promise<ICloudObject | undefined>
	updateObject!: (params: ObjectOrIdOrPath, payload: ContentParams) => Promise<ICloudObject>
	getContent!: (params: ObjectOrIdOrPath) => Promise<string>
	getStream!: (params: ObjectOrIdOrPath) => Promise<Response>

	addObject!: (params: AddParams) => Promise<ICloudObject>
	upsertObject!: (params: AddParams) => Promise<ICloudObject>

	deleteObject!: (obj: ObjectOrIdOrPath) => Promise<void>
}

export function extractObjOrPathKey(params: ObjectOrIdOrPath): [ICloudObjectKey?, IPathKey?] {
	if ('id' in params) return [{ bucketId: params.bucketId, id: params.id }, undefined]
	else if ('path' in params) return [undefined, { bucketId: params.bucketId, path: params.path }]
	else return [undefined, undefined]
}
