import { useComponentHeight, useCurrentRef, useWindowSize, useBrowserDocWin } from '@app/hooks'
import { Flex, FlexC, IMenu, SplitFlex, useBreakpoint, useCurrentBreakpoint, useMenu } from '@app/muiplus'
import anylogger from '@app/anylogger'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { LayoutOptions, LayoutServiceContext } from 'src/services/LayoutService'
import { SearchResultsBackdrop } from 'src/services/Search/SearchResultsBackdrop'
import { ColorModeContext } from './ColorModeContext'
import { MenuTree } from './MenuTree'
import { Sidebar } from './Sidebar'
import { TitleBar } from './TitleBar'

const log = anylogger('GeneralLayout')

interface GeneralLayoutProps {
	children: any
}
export const GeneralLayout = React.forwardRef(function GeneralLayout(props: GeneralLayoutProps, ref: any) {
	const router = useRouter()
	const [showMenu, setShowMenu] = useState<boolean>(true)
	const [showTitleBar, setShowTitleBar] = useState<boolean>(true)
	const [backgroundOverride, setBackgroundOverride] = useState<string | undefined>(undefined)

	const [contentTop, setContentTop] = useState(0)

	const { mode, toggleColorMode } = React.useContext(ColorModeContext)
	const { children } = props
	const [contentRef, setContentRef] = useCurrentRef<HTMLElement>()
	const [titleRef, setTitleRef] = useCurrentRef<HTMLDivElement>()

	const { browserWindow } = useBrowserDocWin()
	const menuVisible = browserWindow?.location.hash == '#menu' || false

	const isMob = useBreakpoint('mobile')
	const isTab = useBreakpoint('tablet')
	const isMobile = isMob || isTab
	const { items } = useMenu()

	const toggleMenu = useCallback(() => {
		if (menuVisible) router.back()
		else if (!menuVisible) router.push({ hash: '#menu' }, undefined, { shallow: true })
	}, [menuVisible, router])

	useEffect(() => {
		if (!contentRef) return
		const r = contentRef.getBoundingClientRect()
		setContentTop(r.top)
	}, [contentRef])

	const onNavigate = (menuItem: IMenu) => {
		if (!menuItem.data) return
		router.replace(menuItem.data)
	}
	const onHome = () => {
		router.push('/Home')
	}
	const contentHeight = useComponentHeight(contentRef)

	// we want to show the menu on mobile, even if showMenu was turned off by descendants
	const mobileMenu = isMobile && (
		<Sidebar top={contentTop} items={items} onNavigate={onNavigate} open={menuVisible} onClose={toggleMenu} />
	)

	const desktopContent = (
		<SplitFlex gap="0px" initialSize="content" localStorageKey="Menu.Width" hidePrimary={!showMenu}>
			<Flex fill>
				<MenuTree items={items} onNavigate={onNavigate} localStorageKey="Menu.Position" />
			</Flex>
			<Flex fill pt="0.25em" pl="0.25em">
				{children}
			</Flex>
		</SplitFlex>
	)
	const content = isMobile ? children : desktopContent
	const [service] = useState<LayoutOptions>({
		showMenu: setShowMenu,
		showTitleBar: setShowTitleBar,
		backgroundOverride: setBackgroundOverride
	})
	const size = useWindowSize()
	const breakpoint = useCurrentBreakpoint()
	useEffect(() => {
		if (breakpoint) log('screen size:', breakpoint, size.width)
	}, [breakpoint, size.width])

	const padding = showTitleBar || isMobile ? 1 : 0
	return (
		<LayoutServiceContext.Provider value={service}>
			<FlexC gap="0px" fill {...props} bgcolor={backgroundOverride}>
				<div ref={setTitleRef} style={{ padding: '0.5em', display: showTitleBar || isMobile ? undefined : 'none' }}>
					<TitleBar onMenuToggle={toggleMenu} onHome={onHome} isMobile={isMobile} />
				</div>
				<Flex
					ref={setContentRef}
					fill
					sx={{
						position: 'relative'
					}}
				>
					{mobileMenu}
					{content}
					{!isMobile && <SearchResultsBackdrop anchorRef={titleRef} height={contentHeight} />}
				</Flex>
			</FlexC>
		</LayoutServiceContext.Provider>
	)
})
