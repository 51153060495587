import { equals } from '@app/utils'
import { TitleDefinition, TitleDefinitionList } from '..//TitleDefinition'
import { ObjectListViewItemAdapter } from './ObjectListViewItemAdapter'
import anylogger from '@app/anylogger'

const log = anylogger('ObjectListViewTitleAdapter')

/**
 * This class accepts an array of objects and then creates title definitions for all the fields of all the objects.
 * The titles and adapted properties then contain the title definitions and ListView adapter respectively.
 * It makes it very easy to display an object list in a list view
 */
export class ObjectListViewTitleAdapter {
	allTitles: TitleDefinitionList = []
	items: any[]
	private adapted: ObjectListViewItemAdapter[]
	private adaptedTitles: TitleDefinitionList = []

	private addTitles(item: any) {
		Object.entries(item).forEach(([k, v]) => {
			if (typeof v == 'object') return
			const t = this.allTitles.find((t) => {
				return t.name.toLocaleLowerCase() == k.toLocaleLowerCase()
			})
			if (!t) {
				this.allTitles.push({ name: k })
			}
		})
	}
	constructor(items: any[]) {
		this.items = items
		// console.time('Adapter')
		items.forEach((item) => this.addTitles(item))
		this.adapted = []
		// console.timeEnd('Adapter')
	}
	getAdapted(titles?: TitleDefinitionList) {
		titles = titles ?? this.allTitles
		const changed = this.titlesChanged(titles)
		if (!this.adapted.length || changed) {
			// log('titlesChanged', changed, this.adaptedTitles, titles)

			const fields = titles.map((t) => t.name)
			this.adapted = this.items.map((item) => {
				return new ObjectListViewItemAdapter(item, fields)
			})
			this.adaptedTitles = titles
		}
		return this.adapted
	}
	titlesChanged(titles: TitleDefinitionList) {
		if (titles?.length != this.adaptedTitles?.length) return true
		return titles.reduce((res, t, idx, all) => res || !equals(t, this.adaptedTitles[idx]), false)
	}
}
