import anylogger from '@app/anylogger'
import { Guid } from './Guid'

const log = anylogger('selectFile')

interface SelectFileParams {
	multiple?: boolean
	accept?: string
}
const doSelectFile = async (params?: SelectFileParams): Promise<File | FileList | undefined> => {
	return new Promise((resolve, reject) => {
		try {
			if (!document) throw new Error('You cannot select a file unless in the browser')
			if (!params) params = {}

			const { multiple = false, accept = '' } = params
			let clicked = false
			const elementId = Guid()

			// this creates an element, but does not insert it into the DOM tree, so I don't think we need to remove it
			let input = document.createElement('input')

			const onFocus = () => {
				// we wait for 2 seconds for fileSelected to be called, and if not, we cancel
				// we wait that long because at 300ms, this timeout would sometimes fire before fileSelected was called.
				// This "shouldn't" cause problems (the late cancel) because you typically do nothing from a "Select File(s)"
				// handler if no files are selected.

				setTimeout(() => {
					if (!clicked) {
						done(undefined)
					}
				}, 2000)
			}
			const done = (res: File | FileList | undefined) => {
				window.removeEventListener('focus', onFocus)
				resolve(res)
			}

			window.addEventListener('focus', onFocus)
			const fileSelected = async (e: any) => {
				clicked = true
				if (!e.target.files.length) done(undefined)
				else if (multiple) done(e.target.files)
				else done(e.target.files[0])
			}

			input.id = elementId
			input.type = 'file'
			input.multiple = multiple
			input.onchange = fileSelected
			input.accept = accept
			input.click()
		} catch (error) {
			log.error(error)
			reject(error)
		}
	})
}
export const selectFile = async (params?: SelectFileParams): Promise<File | undefined> => {
	return doSelectFile({ ...params, multiple: false }) as unknown as File | undefined
}
export const selectFiles = async (params?: SelectFileParams): Promise<FileList | undefined> => {
	return doSelectFile({ ...params, multiple: true }) as unknown as FileList | undefined
}
