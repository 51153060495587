import { JVPInit, JsonValueProvider, ValueOrFunction, getValueFromFunction } from '@app/utils'
import { SxProps } from '@mui/system'

export type FieldType = 'boolean' | 'string' | 'number' | 'bigint' | 'date' | 'itemSelector' | string
export interface ValidationResult {
	preventChange?: boolean
	isError?: boolean
	errorText?: string
}

type ValidateFunction = (value: any) => Promise<ValidationResult>

export class EditorConfig {
	type: FieldType
	disabled?: ValueOrFunction<boolean>
	/**
	 * This can be used to display a progress or temporary value for the edit field (such as when it is initializing).
	 * Returning undefined reverts to default editing behaviour
	 * ! NOTE: This has been removed because once the data to be edited by the dialog has been
	 * ! passed to the dialog, it should not be edited by the client as the changes will not be reflected.
	 * ! i.e. The dialog owns the data until it is saved or cancelled.
	 * */
	// getEditValue?: GetEditValueFunction
	validate?: ValidateFunction
	context?: any
	sx?: SxProps

	static init(init: EditorConfigInit) {
		if (!Array.isArray(init)) {
			if (typeof init == 'object') return init
			return new EditorConfig(init as FieldType)
		}
		const len = init.length
		if (len == 1) return { type: init[0] as FieldType }
		else if (len == 2) {
			return { type: init[0], ...init[1] }
		} else throw new Error(`Invalid number of array entries for createEditorProps`)
	}
	constructor(type: FieldType, options?: Omit<EditorConfig, 'type'>) {
		this.type = type
		this.disabled = options?.disabled
		// this.getEditValue = options?.getEditValue
		this.validate = options?.validate
		this.context = options?.context
	}
}

export type ECInitDefault<T> = FieldType | [FieldType]
type ECInitOptions<T> = [type: FieldType, options: Omit<EditorConfig, 'type'>]

export type EditorConfigInit<T = any> = ECInitDefault<T> | ECInitOptions<T> | EditorConfig
