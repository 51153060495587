import { ClientProxy } from '@app/clientserverutils'
import { GoogleEvent, GoogleEventList, IGoogleCalendar } from '@app/googlecalendar'
import { useLocalStorageOrState } from '@app/hooks'
import { formatDateTime } from '@app/utils'
import { createContext, useState } from 'react'

export class CalendarContextType {
	api!: IGoogleCalendar
	getEventsForDate!: (startDate: string) => Promise<GoogleEventList>
	selectedDate!: string
	setSelectedDate!: (date: Date | string) => void
	getStartDateForMonth!: (date: string) => string
	formatDate!: (date: string | Date) => string
	editEvent!: (event: GoogleEvent) => Promise<void>
}
export const CalendarContext = createContext(new CalendarContextType())
