/**
 * This unit is meant to be a drop in replacement for fast-json-patch.
 * It adds the following functionality:
 * * It allows you to use arraySelector objects insted of just arrayIndexes
 * ** i.e. instead of using a json path of /phones/1/value you can use /phones/{type: home, primary: true}/value
 * ** The arraySelector syntax is honoured in getValueByPointer, applyOperation, applyPatch and applyReducer
 * ** it is not honored or returned by the compare function
 * * The applyOperation and applyPatch methods will "force" the proper objects to be created, much like forceDirectories
 * ** i.e. applying an operation of {op: 'add', path: '/phones/1/value', value: '555-555-5555'} will cause phones[1].value to exist even if applied on a blank object
 * * The compare operation filters out insignificant changes such as '' to undefined or vice versa
 */
export * from 'fast-json-patch'

import {
	PatchResult,
	applyOperation as origApplyOperation,
	applyPatch as origApplyPatch,
	applyReducer as origApplyReducer,
	getValueByPointer as origGetValueByPointer,
	compare as origCompare,
	Validator,
	OperationResult,
	RemoveOperation as RemoveOperationOrig,
	ReplaceOperation as ReplaceOperationOrig,
	AddOperation,
	MoveOperation,
	CopyOperation,
	TestOperation,
	GetOperation
} from 'fast-json-patch'
import anylogger from '@app/anylogger'

const log = anylogger('jsonPatch')

export interface RemoveOperation extends RemoveOperationOrig {
	oldVal?: any
}
export interface ReplaceOperation<T> extends ReplaceOperationOrig<T> {
	oldVal?: T
}
export declare type Operation =
	| AddOperation<any>
	| RemoveOperation
	| ReplaceOperation<any>
	| MoveOperation
	| CopyOperation
	| TestOperation<any>
	| GetOperation<any>

export function applyPatch<T>(
	document: T,
	patch: Operation[],
	validateOperation?: boolean | Validator<T>,
	mutateDocument: boolean = true,
	banPrototypeModifications: boolean = true
): PatchResult<T> {
	let obj = document
	const adjusted = patch.map((operation) => {
		const res = applyForcePath<T>(obj, operation, mutateDocument)
		obj = res.obj
		return res.op
	})
	return origApplyPatch(obj, adjusted, validateOperation, mutateDocument, banPrototypeModifications)
}
export function applyOperation<T>(
	document: T,
	operation: Operation,
	validateOperation: boolean | Validator<T> = false,
	mutateDocument: boolean = true,
	banPrototypeModifications: boolean = true,
	index: number = 0
): OperationResult<T> {
	const { obj, op } = applyForcePath<T>(document, operation, mutateDocument)
	return origApplyOperation(obj, op, validateOperation, mutateDocument, banPrototypeModifications, index)
}

export function applyReducer<T>(document: T, operation: Operation, index: number): T {
	const { obj, op } = applyForcePath<T>(document, operation, true)
	return origApplyReducer(obj, op, index)
}
/**
 * This method returns the value at the specified path or pointer.
 * If the path is not found, undefined is returned instead of throwing an exception, like the original implementation
 */
export function getValueByPointer(document: object, pointer: string) {
	const newPath = adjustPath(document, pointer, true)
	try {
		return origGetValueByPointer(document, newPath)
	} catch (err) {
		return undefined
	}
}
export function compare(tree1: object | Array<any>, tree2: object | Array<any>, invertible?: boolean): Operation[] {
	let res = origCompare(tree1, tree2, invertible)
	// @ts-ignore
	res = res
		.map((change) => {
			let oldVal
			if (['remove', 'replace'].includes(change.op)) oldVal = getValueByPointer(tree1, change.path)
			if (change.op == 'replace') {
				let newVal = change.value
				if (typeof newVal == 'string') newVal = newVal.trim()
				if (typeof oldVal == 'string') oldVal = oldVal.trim()

				// if neither are truthy, then don't bother showing the change
				if (!newVal && !oldVal) return undefined

				// if the oldVal is not truthy, then it is an add
				if (!oldVal) return { ...change, op: 'add' }
				// if the new val is not truthy, it is a remove
				if (!newVal && typeof newVal != 'boolean') return { ...change, op: 'remove', oldVal: oldVal }
			}
			// not sure why we had this in the old code
			// if (change.op == 'remove') {
			// 	// @ts-ignore
			// 	this.newItem = applyOperation(this.newItem, {
			// 		op: 'add',
			// 		path: change.path,
			// 		value: null
			// 	}).newDocument
			// }
			return { ...change, oldVal: oldVal }
		})
		.filter(TypesafeBoolean)

	return res
}

/**
 * This takes a document, an operation and returns an object and a new copy of the operation with an adjusted path.
 */
function applyForcePath<T>(document: T, operation: Operation, mutateDocument: boolean): { obj: T; op: Operation } {
	let obj: T = mutateDocument ? document : { ...document }
	let op = { ...operation }
	let res = forcePath<T>(obj, op.path)
	op.path = res.path
	return { obj, op }
}
function forcePath<T = any>(dst: T, path: string) {
	let paths = path.split('/')
	if (paths.length > 0 && paths[0] == '') paths = paths.slice(1)

	// strip off the last item as that's where the value will go.
	// We just wnat to ensure that all the parent objects are constructed
	// BUT, leave the last value if it is a -, because it indicates that the previous path item is an array
	if (paths.length > 0 && paths[paths.length - 1] != '-') paths = paths.slice(0, paths.length - 1)

	let obj: any = dst
	for (let ii = 0; ii < paths.length; ii++) {
		const path = paths[ii].trim()
		if (path == '-') continue
		const nextPath = ii < paths.length - 1 ? paths[ii + 1].trim() : undefined
		const val = isNum(nextPath ?? '') || nextPath == '-' || isArraySelector(nextPath ?? '') ? [] : {}
		if (isNum(path)) {
			// It IS possible for an array indexed value to be used on an object because
			// the index number is just a key, but we are not going to allow it.
			if (!Array.isArray(obj)) {
				console.error('You can only use an index path for arrays')
				break
			}
			const idx = Number(`${path}`)
			if (typeof obj[idx] == 'undefined') obj[idx] = val
			obj = obj[idx]
		} else if (isArraySelector(path)) {
			if (!Array.isArray(obj)) {
				console.error('You can use an arraySelector path for arrays')
				break
			}
			// when encountering an object in the path, when adapting TO that object,
			// it means that those object properties are defaults for that object.
			// So, we will add those properties to the object, and leave the
			// obj pointer sitting on the same object
			const selector = parseArraySelector(path)
			const target = findObject(obj, selector)
			if (!target) {
				obj.push({ ...selector })
				obj = obj[obj.length - 1]
			} else obj = target
		} else if (nextPath == '-') {
			if (typeof obj[`${path}`] == 'undefined') obj[`${path}`] = val
			obj = obj[`${path}`]
		} else {
			if (typeof obj[`${path}`] == 'undefined') obj[`${path}`] = val
			obj = obj[`${path}`]
		}
	}
	const resPath = adjustPath(dst, path)
	return { obj: dst, path: resPath }
}
/**
 * This takes a JSON Pointer path with optional arraySelector objects) and returns a standard
 * JSON Pointer with the arraySelector converted to an array index if it exists in the object.
 * If the arraySelector does not find a matching object it is left as it is,
 */
function adjustPath(src: any, srcPath: string, gettingValue: boolean = false) {
	let obj: any = src
	let rawPaths = srcPath.split('/')
	if (rawPaths.length > 0 && rawPaths[0] == '') rawPaths = rawPaths.slice(1)

	const resPath = rawPaths.reduce((res: string, path: string) => {
		if (path == '-' && gettingValue) return res
		const selector = parseArraySelector(path)
		if (!selector || !Array.isArray(obj) || !Object.keys(selector).length) {
			if (obj) obj = obj[`${path}`]
			return res + '/' + path
		}

		const target = findObject(obj, selector)
		const idx = obj.indexOf(target)
		if (target) obj = target
		else obj = undefined

		// if we find a matching object, we change the path item to
		// standard array index path syntax.
		// Otherwise, we leave it the same, which will fail getting the value
		// from the object as the path is not valid and does not exists
		if (idx >= 0) return res + '/' + idx.toString()
		else return res + '/' + path
	}, '')
	return resPath
}

function findObject(obj: any[], arraySelector: any) {
	return obj.find((item) => {
		return Object.entries(arraySelector).reduce((res, [key, val]) => {
			if (!res || toString(item[`${key}`]) != toString(val)) return false
			return true
		}, true)
	})
}
function parseArraySelector(str: string) {
	if (!isArraySelector(str)) return undefined
	str = str.trim()
	if (!str.startsWith('{') || !str.endsWith('}')) return {}

	str = str.slice(1, str.length - 1)
	const props = str.split(',')
	const res = props.reduce((res: any, prop) => {
		let [name, valStr] = prop.split(':')
		let val: any = valStr
		val = val.trim()
		if (!val) return res
		let hasQuotes = false
		if (`'"`.includes(val[0]) && val[val.length - 1] == val[0]) {
			hasQuotes = true
			val = val.slice(1, val.length - 1)
		}
		if (!hasQuotes) {
			if (val == 'true') val = true
			else if (val == 'false') val = false
		}

		res[`${name.trim()}`] = val
		return res
	}, {})
	return res
}
function isArraySelector(str: string) {
	return str.includes('{')
}
function toString(val: any) {
	if (typeof val == 'string') return val
	if (typeof val == 'undefined') return 'undefined'
	if (['object', 'array'].includes(typeof val)) return JSON.stringify(val)
	return val.toString()
}
function isNum(str: string): boolean {
	let s = str.trim().replaceAll(',', '')
	if (s.length && s[0] == '+') s = s.slice(1)
	const parsed = parseInt(str).toString()
	return parsed == s
}

export const TypesafeBoolean = Boolean as any as <T>(x: T | false | undefined | null | '' | 0) => x is T
