import anylogger from '@app/anylogger'
import { createWordIndex, numbersOnly } from './StrUtils'

type SortType = 'asc' | 'desc'
type SortDef<T> = keyof T | [keyof T, SortType]

const log = anylogger('objectSort')

export interface SortField<T = any> {
	field: keyof T
	isDesc?: boolean
	isNumeric?: boolean
}

/**
 * This returns a Compare Function that can be passed to the array.sort function.
 * It allows you to sort based on sort criteria passed in.
 * @param fields A list of fields to sort on.  This can be:
 * * A single field name or an array with a field name and a {@link SortType}. Either of these (i.e. 'LastName' or ['LastName', 'desc]) are
 * considered a {@link SortDef}
 * * An array of {@link SortDef|SortDefs}
 * * An array of {@link SortField}
 * @param getter This is an optional function that is called to get the value of an object, based on the field name
 * @returns A method that can be passed to an array.sort function.
 * * i.e. list.sort( objectSort([ ['LastName','desc'], 'FirstName' ]) )
 */
export function objectSort<T extends object = any>(
	fields: SortDef<T> | SortDef<T>[] | SortField<T>[],
	getter?: (obj: T, field: keyof T) => any
): (a: T, b: T) => number {
	const createSortFields = (fields: SortDef<T> | SortDef<T>[] | SortField<T>[]): SortField<T>[] => {
		let flds: any[]
		if (Array.isArray(fields)) flds = fields
		else flds = [fields]

		return flds.map((fld) => {
			// If this is a SortField, then return that
			if (fld.field) return fld
			if (typeof fld == 'string') return { field: fld, isDesc: false, isNumeric: false } as SortField<T>
			else {
				const [fieldName, sort] = fld as [keyof T, SortType]
				return { field: fieldName, isDesc: sort == 'desc', isNumeric: false }
			}
		})
	}
	function getVal(obj: T, srt: SortField<T>): any {
		return getter ? getter(obj, srt.field) : obj[srt.field]
	}
	return (a: T, b: T) => {
		const sortFields = createSortFields(fields)
		let res: number = 0
		for (let ii = 0; ii < sortFields.length; ii++) {
			// if a previous compare is already definitive, just break
			if (res) break

			const srt = sortFields[ii]
			const valA = getVal(a, srt)
			const valB = getVal(b, srt)
			res = valueCompare(valA, valB, srt.isNumeric)

			if (srt.isDesc) res = res * -1
		}
		return res
	}
}

/**
 * This is used by objectSort to compare two values for sorting.  It compares two values, taking into account:
 * * whether one or both values are undefined
 * * whether both values are numbers (in which it sorts numerically instead of alphabetically)
 * * whether isNumeric is true (in which case it forces numeric sorting)
 * * whether the values are both boolean, sorting appropriately
 * * whether the values are both strings, sorting appropriately
 * * if none of the above are true, it returns 0, treating both values as equal, and sends a log warning indicating that it cannot sort the specified types
 * @param valA
 * @param valB
 * @param isNumeric Forces numeric sorting
 * @returns a number indicating the compare value for sorting
 */
export function valueCompare(valA: any, valB: any, isNumeric: boolean = false): number {
	let res: number = 0
	if (typeof valA == 'undefined' || typeof valB == 'undefined') {
		if (!valA && !valB) res = 0
		if (!valB) res = 1
		else res = -1
	} else if (isNumeric) {
		valA = Number(numbersOnly(valA)) ?? 0
		valB = Number(numbersOnly(valB)) ?? 0
		// @ts-ignore
		const numA = parseFloat(valA)
		// @ts-ignore
		const numB = parseFloat(valB)
		res = numA - numB
	} else if (['number', 'bigint'].includes(typeof valA) && ['number', 'bigint'].includes(typeof valB)) {
		if (valA > valB) res = 1
		else if (valA < valB) res = -1
		else res = 0
	} else if (typeof valA == 'boolean' && typeof valB == 'boolean') {
		if (valA && !valB) res = 1
		else if (!valA && valB) res = -1
		else res = 0
	} else if (typeof valA == 'string' && typeof valB == 'string') {
		res = valA.localeCompare(valB as string)
	} else {
		log.warn(`valueCompare: Cannot compare field ${typeof valA} to ${typeof valB}`)
	}
	return res
}
