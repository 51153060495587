import * as React from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import OrigNextLink, { LinkProps as OrigNextLinkProps } from 'next/link'
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'
import { styled } from '@mui/material'

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({})
// @ts-ignore
interface NextLinkComposedProps
	extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
		Omit<OrigNextLinkProps<any>, 'href' | 'as'> {
	to: OrigNextLinkProps<any>['href']
	linkAs?: OrigNextLinkProps<any>['as']
	href?: OrigNextLinkProps<any>['href']
}

export const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(function NextLinkComposed(props, ref) {
	const { to, linkAs, href, replace, scroll, shallow, prefetch, locale, children, ...other } = props

	return (
		<OrigNextLink
			href={to}
			prefetch={prefetch}
			as={linkAs}
			replace={replace}
			scroll={scroll}
			shallow={shallow}
			passHref
			locale={locale}
			{...other}
		>
			{children}
			{/* <Anchor ref={ref} {...other} /> */}
		</OrigNextLink>
	)
})

export type NextLinkProps = {
	activeClassName?: string
	as?: OrigNextLinkProps<any>['as']
	href: OrigNextLinkProps<any>['href']
	linkAs?: OrigNextLinkProps<any>['as'] // Useful when the as prop is shallow by styled().
	noLinkStyle?: boolean
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
	Omit<MuiLinkProps, 'href'>

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
const NextLink = React.forwardRef<HTMLAnchorElement, NextLinkProps>(function Link(props, ref) {
	const {
		activeClassName = 'active',
		as: linkAs,
		className: classNameProps,
		href,
		noLinkStyle,
		role, // Link don't have roles.
		...other
	} = props

	const router = useRouter()
	const pathname = typeof href === 'string' ? href : href.pathname
	const className = clsx(classNameProps, {
		[activeClassName]: router.pathname === pathname && activeClassName
	})

	const isExternal = typeof href === 'string' && (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0)

	if (isExternal) {
		if (noLinkStyle) {
			return <Anchor className={className} href={href} ref={ref} {...other} />
		}

		return <MuiLink className={className} href={href} ref={ref} {...other} />
	}

	if (noLinkStyle) {
		return <NextLinkComposed className={className} ref={ref} to={href} {...other} />
	}

	return <MuiLink component={NextLinkComposed} linkAs={linkAs} className={className} ref={ref} to={href} {...other} />
})

export { NextLink }
