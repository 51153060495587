import { useBreakpoint } from '@app/muiplus'
import { Button } from '@mui/material'
import React, { useCallback, useContext } from 'react'
import { signIn, signOut } from 'next-auth/react'
import { useSession } from 'src/services/useSession'
import anylogger from '@app/anylogger'
import { useRouter } from 'next/router'

const log = anylogger('SessionStatus')

export const SessionStatus = function SessionStatus(props: any) {
	const s = useSession()
	const router = useRouter()

	const [session, loading] = s

	const isMobile = useBreakpoint('mobile')

	const logOut = useCallback(() => {
		signOut({ callbackUrl: '/' })

		return true
	}, [])
	const logIn = useCallback((context: any) => {
		router.push('/auth/Login')
		return true
	}, [])
	if (session) return <Button onClick={logOut}>Logout</Button>
	return <Button onClick={logIn}>Login</Button>
}
