import anylogger from '@app/anylogger'
import { ClientProxy } from '@app/clientserverutils'
import { GoogleEvent, GoogleEventList, IGoogleCalendar } from '@app/googlecalendar'
import { useCurrentRef, useEvent, useKeyBinder, useLocalStorageOrState } from '@app/hooks'
import { Buttons, Flex, FlexC, Save, useBreakpointValues, useCurrentBreakpoint, useModal } from '@app/muiplus'
import { adjustDate, adjustMonth, adjustSeconds, daysDiff, formatDateTime, parseDateTime, valueCompare } from '@app/utils'
import { Box } from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { CalendarContext, CalendarContextType } from './CalendarContext'
import { CalendarDay } from './CalendarDay'
import { CalendarDisplayEvent } from './CalendarDisplayEvent'
import { CalendarEditDialog } from './CalendarEditDialog'
import { CalendarHeader } from './CalendarHeader'
import { getDateFromUnion } from './calendarUtils'

export const log = anylogger('Calendar')

// interface ICache<K extends string | number | symbol, T> {
// 	getValue(key: K): T | undefined
// 	hasValue(key: K): boolean
// 	setValue(key: K, val: T): void
// }
class Cache<K extends string | number | symbol, T> {
	cache: Record<K, Promise<T>> = {} as Record<K, Promise<T>>
	async getValue(key: K, forceRefresh?: boolean): Promise<T> {
		let res = this.cache[key]
		if (typeof res == 'undefined' || forceRefresh) {
			res = this.retrieveValue(key)
			this.cache[key] = res
		}
		return await res
	}
	protected async retrieveValue(key: K): Promise<T> {
		throw new Error('Method not implemented.')
	}
	hasValue(key: K): boolean {
		return typeof this.cache[key] != 'undefined'
	}
	setValue(key: K, val: T): void {
		this.cache[key] = Promise.resolve(val)
	}
}
class GoogleEventCache extends Cache<string, GoogleEventList> {
	api: IGoogleCalendar
	constructor(api: IGoogleCalendar) {
		super()
		this.api = api
	}
	protected async retrieveValue(key: string): Promise<GoogleEventList> {
		const end = adjustDate(key, 42)
		const events = await this.api.listEvents({
			timeMin: key + 'T00:00:00Z',
			timeMax: end.toISOString()
		})
		function sort(a: GoogleEvent, b: GoogleEvent) {
			let startA = a.start.dateTime
			if (!startA) startA = a.start.date + 'T00:00:00'
			let startB = b.start.dateTime
			if (!startB) startB = b.start.date + 'T00:00:00'
			return valueCompare(startA, startB)
		}
		events.sort(sort)
		return events
	}
}

interface CalendarControllerProps {}
export default function CalendarController(props: CalendarControllerProps) {
	const [api] = useState<IGoogleCalendar>(new ClientProxy(new IGoogleCalendar(), '/api/calendar') as IGoogleCalendar)
	const [refreshItems, setRefreshItems] = useState(0)

	const initDate = formatDateTime(new Date(), 'yyyy-mm-dd')
	const [selectedDate, setSelectedDateInternal] = useLocalStorageOrState<string>('Calendar.SelectedDate', initDate)
	const [lastSelectedDate, setLastSelectedDate] = useState(initDate)

	// const [selectedDate, setSelectedDateInternal] = useState(() => formatDateTime(new Date(), 'yyyy-mm-dd'))

	const [dateCache] = useState<GoogleEventCache>(new GoogleEventCache(api))
	const { customDialog, alertDialog } = useModal()

	const formatDate = useCallback((date: Date | string) => {
		return formatDateTime(date, 'yyyy-mm-dd')
	}, [])
	const setSelectedDate = useCallback(
		(date: Date | string) => {
			setSelectedDateInternal(formatDate(date))
		},
		[formatDate, setSelectedDateInternal]
	)
	const refresh = useCallback(() => {
		setRefreshItems((v) => v + 1)
	}, [])
	const editEvent = useCallback(
		async (event: GoogleEvent) => {
			const res = await customDialog({
				title: 'Calendar Event',
				dialog: <CalendarEditDialog event={event} />,
				buttons: [Buttons.Save, Buttons.Discard]
			})

			if (res.result == Save) {
				try {
					await api.updateEvent(res.data)
				} catch (err: any) {
					await alertDialog({ title: 'Error Updating Calendar Event', content: err.message || err.toString() })
				} finally {
					refresh()
				}
			}
		},
		[alertDialog, api, customDialog, refresh]
	)
	const nextDay = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, 1))
	}, [selectedDate, setSelectedDate])
	const prevDay = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, -1))
	}, [selectedDate, setSelectedDate])
	const nextWeek = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, 7))
	}, [selectedDate, setSelectedDate])
	const prevWeek = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, -7))
	}, [selectedDate, setSelectedDate])
	const nextMonth = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, 28))
	}, [selectedDate, setSelectedDate])
	const prevMonth = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, -28))
	}, [selectedDate, setSelectedDate])

	const { useKeyBinding } = useKeyBinder()
	useKeyBinding('ArrowRight', nextDay)
	useKeyBinding('ArrowLeft', prevDay)
	useKeyBinding('ArrowDown', nextWeek)
	useKeyBinding('ArrowUp', prevWeek)
	useKeyBinding('PageDown', nextMonth)
	useKeyBinding('PageUp', prevMonth)

	const getStartDateForMonth = useCallback(
		(date: string) => {
			const { wd, mm: selMonth } = parseDateTime(date)
			const sunday = formatDate(adjustDate(date, wd * -1))
			const { dd, mm: sunMonth } = parseDateTime(sunday)
			const weeks = selMonth == sunMonth ? Math.ceil(dd / 7) : 0
			const subDays = weeks * 7 + wd
			return formatDate(adjustDate(date, -1 * subDays))
		},
		[formatDate]
	)

	const getEventsForDate = useCallback(
		async (date: string) => {
			// this does nothing, but gets rid of à warning that refreshItems is not needed in the dependency list
			refreshItems
			const startDate = getStartDateForMonth(date)

			const events = await dateCache.getValue(formatDate(startDate), startDate == lastSelectedDate)
			setLastSelectedDate(startDate)
			const prevDate = adjustMonth(date, -1)
			const prevStart = getStartDateForMonth(formatDate(prevDate))
			const nextDate = adjustMonth(date, 1)
			const nextStart = getStartDateForMonth(formatDate(nextDate))

			dateCache.getValue(formatDate(prevStart))
			dateCache.getValue(formatDate(nextStart))

			return events
		},
		[dateCache, formatDate, getStartDateForMonth, lastSelectedDate, refreshItems]
	)

	const ctx: CalendarContextType = { api, getEventsForDate, selectedDate, setSelectedDate, editEvent, getStartDateForMonth, formatDate }

	return (
		<CalendarContext.Provider value={{ ...ctx }}>
			<CalendarView />
		</CalendarContext.Provider>
	)
}
export function CalendarView() {
	const { getEventsForDate, selectedDate, setSelectedDate, getStartDateForMonth, formatDate } = useContext(CalendarContext)
	const [events, setEvents] = useState<Record<string, CalendarDisplayEvent[]>>({})

	const [ref, setRef] = useCurrentRef<HTMLElement>()
	const gap = useBreakpointValues('2px', { mobile: '1px' })

	const startDate = getStartDateForMonth(selectedDate)

	const onWheel = useCallback(
		(e: any) => {
			let days = 28
			if (e.deltaY < 0) days = days * -1
			setSelectedDate(adjustDate(selectedDate, days))
		},
		[selectedDate, setSelectedDate]
	)
	useEvent(ref, 'wheel', onWheel)

	const createKeys = useCallback((e: GoogleEvent) => {
		let sd = e.start.dateTime
		if (!sd) sd = e.start.date + 'T00:00:00'
		let ed = e.end.dateTime
		if (!ed) ed = e.end.date + 'T00:00:00'

		const sDate = new Date(sd)
		const eDate = new Date(ed!)
		let date = sDate
		let res = []
		do {
			const dt = parseDateTime(date)
			res.push(`${dt.mm}-${dt.dd}`)
			date = adjustDate(date, 1)
		} while (date < eDate)
		return res
	}, [])

	useEffect(() => {
		let res: Record<string, CalendarDisplayEvent[]> = {}
		const createDisplayEvents = (e: GoogleEvent, multiDayOnly: boolean) => {
			const sDate = getDateFromUnion(e.start)
			let eDate = getDateFromUnion(e.end)

			const isMultiDay = daysDiff(sDate, eDate) > 1
			if (multiDayOnly && !isMultiDay) return
			if (!multiDayOnly && isMultiDay) return

			let slot: number = -1
			let date = sDate
			do {
				const dt = parseDateTime(date)
				let dayKey = `${dt.mm}-${dt.dd}`
				let displayEvents = res[dayKey]
				if (!displayEvents?.length) {
					displayEvents = []
					res[dayKey] = displayEvents
				}

				let de: CalendarDisplayEvent
				if (!isMultiDay) de = { event: e, multiDay: false }
				else {
					if (slot < 0) slot = displayEvents.length
					const effectiveEndDate = adjustSeconds(eDate, -1)
					de = {
						event: e,
						multiDay: true,
						slot: slot,
						isFirstDay: formatDateTime(sDate, 'yyyy-mm-dd') == formatDateTime(date, 'yyyy-mm-dd'),
						isLastDay: formatDateTime(effectiveEndDate, 'yyyy-mm-dd') == formatDateTime(date, 'yyyy-mm-dd')
					}
				}
				displayEvents.push(de)

				date = adjustDate(date, 1)
			} while (date < eDate)

			// let date = sDate
			// let res = []
			// do {
			// 	const dt = parseDateTime(date)
			// 	res.push(dayKey)
			// 	date = adjustDate(date, 1)
			// } while (date < eDate)
			return res
		}

		getEventsForDate(selectedDate).then((events) => {
			events.forEach((e) => createDisplayEvents(e, true))
			events.forEach((e) => createDisplayEvents(e, false))
			setEvents(res)

			// const eventDict = events.reduce((res, e, idx) => {
			// 	const keys = createKeys(e)
			// 	keys.forEach((key) => {
			// 		if (!res[key]) res[key] = []
			// 		res[key].push(e)
			// 	})

			// 	return res
			// }, {} as any)
			// setEvents(eventDict)
		})
	}, [createKeys, getEventsForDate, selectedDate])

	const getDays = useCallback(() => {
		let res = []
		let curDate = startDate
		for (let week = 0; week < 6; week++) {
			for (let day = 0; day < 7; day++) {
				let dayEvents: CalendarDisplayEvent[] | undefined = undefined
				const dt = parseDateTime(curDate)
				const key = `${dt.mm}-${dt.dd}`
				if (events) {
					dayEvents = events[key]
				}

				res.push(<CalendarDay key={key} date={curDate} events={dayEvents} />)
				curDate = formatDate(adjustDate(curDate, 1))
			}
		}
		return res
	}, [startDate, events, formatDate])
	const bkp = useCurrentBreakpoint()
	// const bgColor = bkp == 'mobile' ? 'lightgray' : undefined
	const bgColor = 'lightgray'
	return (
		<FlexC fill ref={setRef} fontSize="12px">
			<CalendarHeader />
			<Flex fill>
				<Box
					display="grid"
					width="100%"
					bgcolor={bgColor}
					gridTemplateColumns="repeat(7,1fr)"
					gridTemplateRows="repeat(6,1fr)"
					gap={gap}
				>
					{getDays()}
				</Box>
			</Flex>
		</FlexC>
	)
}
