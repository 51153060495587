import { Box, lighten, useTheme } from '@mui/material'
import { CalendarDisplayEvent } from './CalendarDisplayEvent'
import { daysDiff, formatDateTime } from '@app/utils'
import { useCurrentBreakpoint } from '@app/muiplus'
import { getDateFromUnion } from './calendarUtils'

interface CalendarEventProps {
	displayEvent?: CalendarDisplayEvent
	onClick?: (e: CalendarDisplayEvent) => void
	isDetailed?: boolean
}
export function CalendarEvent({ displayEvent, onClick, isDetailed }: CalendarEventProps) {
	const theme = useTheme()
	let eventProps = {}
	let tooltip = ''
	let desc
	let bg = 'transparent'
	let cursor
	const bkp = useCurrentBreakpoint()
	const showDetails = () => {
		if (onClick && displayEvent) onClick(displayEvent)
	}
	if (displayEvent && onClick) {
		const event = displayEvent.event
		const sDate = getDateFromUnion(event.start)
		const eDate = getDateFromUnion(event.end)

		let time = event.start.dateTime ? formatDateTime(event.start.dateTime, 'hh:nn') + ' ' : ''
		let dateStr = formatDateTime(sDate, 'wdl dd mmmm yyyy')
		if (time) dateStr = dateStr + ', ' + time
		const isMultiDay = daysDiff(sDate, eDate) > 1
		if (isMultiDay) {
			let tmpStr = formatDateTime(eDate, 'wdl dd mmmm yyyy')
			let time = event.end.dateTime ? formatDateTime(event.end.dateTime, 'hh:nn') + ' ' : ''
			if (time) tmpStr = tmpStr + ', ' + time
			dateStr = dateStr + ' - ' + tmpStr
		}

		cursor = 'pointer'

		tooltip = tooltip + event.summary
		tooltip += '\n' + dateStr
		if (event.description) tooltip += '\n' + event.description
		if (isDetailed) tooltip = ''
		desc = event.summary
		if (bkp != 'mobile') desc = time + desc
		if (displayEvent.multiDay && !displayEvent.isFirstDay) desc = ' '

		// bg = lighten(theme.palette.primary.light, 0.2)
		bg = theme.palette.primary.light

		const padding = bkp == 'mobile' ? '0.1em' : '0.25em'
		const margin = bkp == 'mobile' ? '0.1em' : '0.25em'
		eventProps = { px: padding, mx: margin }
		if (displayEvent.multiDay) {
			if (displayEvent.isFirstDay) {
				eventProps = { ...eventProps, mr: '0px', borderRadius: '0.25em 0 0 0.25em' }
			} else if (displayEvent.isLastDay) {
				eventProps = { ...eventProps, ml: '0px', borderRadius: '0 0.25em 0.25em 0' }
			} else {
				eventProps = { ...eventProps, mx: '0px' }
			}
		} else {
			eventProps = { ...eventProps, mx: margin, borderRadius: '0.25em' }
		}
		// if (bkp == 'mobile') {
		// } else {
		// 	eventProps = {
		// 		px: '0.25em',
		// 		mx: '0.25em',
		// 		textOverflow: 'ellipsis'
		// 	}
		// }
	} else desc = <br />
	return (
		<Box
			{...eventProps}
			overflow="hidden"
			whiteSpace="pre"
			bgcolor={bg}
			tooltip={tooltip}
			onClick={showDetails}
			sx={{
				cursor
			}}
		>
			{desc}
		</Box>
	)
}
