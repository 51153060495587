import { WebMenu } from '@app/utils'
import anylogger from '@app/anylogger'

const log = anylogger('filterMemberMenu')

export const filterMemberMenu = (roles: string[], menu: WebMenu) => {
	if (typeof window == 'undefined' || !roles || typeof menu == 'undefined') return menu
	const res = [...menu]

	if (!res) return res
	if (!roles || !Array.isArray(roles) || !roles.includes('MembershipEditor')) {
		let membership = res.find((item) => item.caption.toLocaleLowerCase() == 'membership')
		if (membership?.children) membership.children = membership.children.filter((item) => item.caption.toLocaleLowerCase() != 'admin')
	}
	return res
}
