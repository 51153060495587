import { useSession } from 'next-auth/react'
import { createContext, useContext, useEffect, useState } from 'react'

class Context {
	isWebEditor: boolean = false
	isPictureEditor: boolean = false
	isKnightLetterEditor: boolean = false
	isMembershipEditor: boolean = false
	isFileEditor: boolean = false
	isCalendarEditor: boolean = false
}
export const PermissionContext = createContext(new Context())

export const usePermission = () => {
	const val = useContext(PermissionContext)
	return val
}

export const PermissionService = (props: any) => {
	const { data, status } = useSession()
	const [state, setState] = useState<Context>(new Context())

	useEffect(() => {
		if (status != 'authenticated' || !data) return setState(new Context())
		// @ts-ignore
		const roles: string[] = data.roles ? (data.roles as string[]) : []
		if (!Array.isArray(roles)) return

		const val = {
			isWebEditor: roles.includes('WebEditor'),
			isPictureEditor: roles.includes('PictureEditor'),
			isKnightLetterEditor: roles.includes('KnightLetterEditor'),
			isMembershipEditor: roles.includes('MembershipEditor'),
			isFileEditor: roles.includes('FileEditor'),
			isCalendarEditor: roles.includes('CalendarEditor')
		}
		setState(val)
	}, [data, status])

	return <PermissionContext.Provider value={state}>{props.children}</PermissionContext.Provider>
}
