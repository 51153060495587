import { TitleDefinition, TitleDefinitionList } from './TitleDefinition'
import { getValueByPointer } from '@app/jsonpatch'
import { ReactElement, ReactNode } from 'react'
import { JVPInit, JsonValueProvider } from '@app/utils'
import anylogger from '@app/anylogger'

const log = anylogger('ColumnMapper')

export type Title = string | TitleDefinition
export type GetDisplayValueFunction<T extends object = any> = (val: any, obj: T) => any

export interface ColumnTypeObj<T extends object = any> {
	title: Title
	providerInit: JVPInit<T>
	getDisplayValue?: GetDisplayValueFunction<T>
}

export type ColumnType<T extends object = any> =
	| [title: Title, providerInit: JVPInit<T>, getDisplayValue?: GetDisplayValueFunction<T>]
	| ColumnTypeObj<T>

// We could also do this to allow a setter to be specified, but may not have much use in a List view.
// export type ValueSetter<T> = string | ((obj: T, value: any) => void)
// export type ColumnType<T> = [title: Title, getter: ValueGetter<T>, setter?: ValueSetter<T>]

export interface ColumnDefinition<T extends object = any> {
	title: TitleDefinition
	provider: JsonValueProvider<T>
	getDisplayValue?: GetDisplayValueFunction<T>
}

export type CellObj = { value: string; displayValue: ReactNode }
export type CellData = string | CellObj
export type RowData = CellData[]

/**
 * This class can be used to easily create a ListViewProviderBase implementation. To the constructor,
 * pass an array of ColumnType, each of which defines a title definition and a value getter based on a passed in object, and optionally a function to get a displayValue, which may be a React component
 * * The "Title" can either be just the column name, or a full Title definition.
 * * the ValueType<T> can be either an object prop name, a function that returns a value from a passed in object,
 *   or a React Element.
 */
export class ColumnMapper<T extends object = any> {
	map: ColumnDefinition<T>[]
	allTitles: TitleDefinitionList
	constructor(cols: ColumnType<T>[]) {
		this.map = cols.map((col) => {
			// const [t, value] = col
			let t: TitleDefinition | string
			let provider = undefined
			let getDisplayValue = undefined
			if (Array.isArray(col)) {
				t = col[0]
				provider = JsonValueProvider.init(col[1])
				if (col.length > 2) getDisplayValue = col[2]
			} else {
				t = col.title
				provider = JsonValueProvider.init(col.providerInit)
				getDisplayValue = col.getDisplayValue
			}
			let title: TitleDefinition
			if (typeof t == 'string') title = { name: t }
			else title = t
			return { title, provider, getDisplayValue }
		})
		// this never changes, so generate allTitles now so that we always return the same object reference to the list instead of always returning a new object list in getAllTitles
		this.allTitles = this.map.map((def) => def.title)
	}
	getAllTitles() {
		return this.allTitles
	}
	getValues(obj: T): RowData {
		return this.map.map((def) => {
			const val = def.provider.getValue(obj)

			if (!def.getDisplayValue) return val

			return { value: val, displayValue: def.getDisplayValue(val, obj) }
		})
	}
}
